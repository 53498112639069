import { customer2Normal, customer2Owner, customerAndAccountExprot, customerAttention, customerDel, customerVerify, customerVerifyFollowup } from "@api/index"
import { setTaskObj } from "@store/actions"
import { productApi } from "@utils/index"
import { Modal } from "antd"
import { stringify } from "qs"
import React, { Key } from "react"
import { useHistory } from "react-router-dom"
const customerDelApi = productApi(customerDel)
const customerAttentionApi = productApi(customerAttention)
const customerVerifyFollowupApi = productApi(customerVerifyFollowup)
const customer2OwnerApi = productApi(customer2Owner)
const customer2NormalApi = productApi(customer2Normal)
const exprotUserCustomerAndAccountApi = productApi(customerAndAccountExprot)

export const useCustomerListOperate = (onSuccess?: any) => {
    const history = useHistory()
    // 删除,批量删除 操作
    const delAction = (params: {ids: Key[]}) => {
        Modal.confirm({
            title: '确认要删除该数据吗?',
            content: '删除后当前内容将永久删除，不可恢复。',
            okText: '确认',
            cancelText: '取消',
            onOk: async() => {
                await customerDelApi(params, true, onSuccess)
            },
        })
    }

    // 关注 操作
    const attentionAction = (params: {id: Key, type: 0 | 1}) => customerAttentionApi(params, true, onSuccess)
    
    const exprotUserCustomerAndAccount = () => exprotUserCustomerAndAccountApi( true, onSuccess)


    // 转回个人线索
    const returnOwnerAction = (params: {ids: Key[]}) => {
        Modal.confirm({
            title: '转回个人线索',
            content: '客户将转为个人线索，请到【我的线索】列表持续跟进',
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                await customer2OwnerApi(params, true, onSuccess)
            },
        })
    }

    // 退回普通客户
    const returnBackAction = (params: {ids: Key[]}) => Modal.confirm({
        title: '退回普通客户',
        content: '请谨慎操作，确定将重点客户退回普通客户？',
        okText: '确认',
        cancelText: '取消',
        onOk: async() => {
            await customer2NormalApi(params, true, onSuccess)
        },
    });

    // 判断是否可以添加跟进
    const isCanaddFollowAction = async (params: {customerName: string, customerId: Key, [key: string]: any}) => {
        return true  // 逻辑又又又改了，又不做判断了
        const {customerName, customerId} = params
        const { data } = await customerVerifyFollowupApi(params, false);
        return !data ? (Modal.confirm({
            title: '温馨提示',
            content: <>录入跟进记录前，请先<a key="update" onClick={() => {
                            Modal.destroyAll()
                            history.push(`/customer/businessAdd?${stringify({customerName, customerId, routeName: '创建商机', fromPath: '/customer/list'})}`)
                        }}>创建商机</a>。
                    </>,
            okText: '前往',
            cancelText: '取消',
            onOk: async() => {
                history.push(`/customer/businessAdd?${stringify({customerName, customerId, routeName: '创建商机', fromPath: '/customer/list'})}`)
            },
        }), false) : true
    }

    return {
        delAction,
        attentionAction,
        returnOwnerAction,
        returnBackAction,
        isCanaddFollowAction,
        exprotUserCustomerAndAccount
    }
}
