import React, { Key, useEffect, useMemo, useRef, useState } from "react"
import { ExportButton, FormDragSetting, getFormDragSettingOptions, ImportButton, ProTableWithFormSetting, setColumnsByOptions, ProFormTreeSelect, Icon, ProFormCustTreeSelect, ProFormCustTreeSelectProps, ViewScope } from "@components/index"
import { columnsFn, customerScopeOptions, customerViewOptions, followUpTypeOptions, hasBusinessOptions, helperListColumnsFn } from './data'
import type { OperateActionType, TableListItem } from './data'
import ProTable, { ActionType, ProTableProps } from '@ant-design/pro-table';
import type { ModalFormProps, ProFormInstance } from '@ant-design/pro-form' 
import ProForm, { 
    ModalForm, 
    ProFormText, 
    ProFormCascader, 
    ProFormUploadDragger, 
    ProFormSelect, 
    ProFormTextArea, 
    ProFormDateTimePicker, 
    ProFormMoney, 
    ProFormDatePicker, 
    ProFormItem, 
    ProFormRadio, 
    ProFormDependency 
} from '@ant-design/pro-form';
import { Button, Modal, Tabs, Select, Table, Cascader, Tag, message, ButtonProps, Dropdown, Menu, Alert, Space, AlertProps, SelectProps } from "antd";
import { Link, useHistory } from "react-router-dom";
import { 
    customerList, 
    customerDel, 
    customerAttention, 
    customerTransferToWho, 
    customerBackToPool, 
    customerFollowAdd, 
    customerHelperList, 
    customerHelperDel, 
    customerHelperSave, 
    BASEURL, 
    ossImport, 
    customerCluePoolCanAdd, 
    customerHasBussiness, 
    customerCanAdd, 
    customerListByName, 
    customerFollowAddImportant, 
    customerFollowEditImportant, 
    customerVerify,
    customerToImportant,
    businessListByName,
    getFollowUpById,
    getFollowUpByBusinessId,
    getSystemRuleNotFollowupDay,
    customerGetInfoById
} from '@api/index'
import { treeDataDisabledRules, getToken, productApi, toFormData, treeDataToFlatArr, getCheckedColumnsStateByColumns, getCustomerReturnDesc, isFunction } from "@utils/util";
import { useCantactsByCustomerIdHooks, useCluesPoolDataHooks, useCommonApiHooks, useDeptDataWithRoleHooks, useDictEnumHooks, useHasAuthCode } from "@hooks/index";
import { stringify } from "qs";
import { useSelector } from "react-redux";
import { StateType } from "@store/index";
import { AddContact } from "@components/EnterpriseDetail/cpnts/Contact";
import { ExportButtonWithBatch } from "@components/Common/ExportButton";
import { debounce } from "lodash";
import moment from "moment";
import { SalesStageToolTip } from "../Business/cpnts/AddBusiness";
import { custOptions, yesNoOptions } from "@utils/enum";
import { CustTreeSelect, CustTreeSelectProps } from "@components/Workbench";
import { useCustomerListOperate } from "./hook";
import { useRequest } from "ahooks";
import { customerInfoApi } from "./cpnts/AddCustomer";
import request from "@utils/request";

export type CustomerViewProps = {
    view?: string;
    scope?: string;
    onChange?: (type: string, val: any) => void;
}

const columnStateDefaultValue: any = {
    FY22总消费: {show: false},
    FY23总消费: {show: false},
    FY24总消费: {show: false},
    accountModes: {show: false},
    accountNames: {show: false},
    businessLine: {show: false},
    customerLevel: {show: false},
    customerName: {show: true, fixed: 'left'},
    customerPropertyStr: {show: false},
    customerSource: {show: false},
    daysSinceFollowup: {show: true},
    followUpContent: {show: false},
    followupTime: {show: true},
    inputTime: {show: true},
    isAttention: {show: true, fixed: 'left'},
    labels: {show: false, },
    option: {show: true, fixed: 'right'},
    ownerName: {show: true},
    regionId: {show: false},
    state: {show: true},
    synids: {show: false},
    verifiedNames: {show: false}
}

const { TabPane } = Tabs;

const getApi = productApi(customerList) // 获取客户列表
const customerDelApi = productApi(customerDel)
const customerAttentionApi = productApi(customerAttention)
export const customerCluePoolCanAddApi = productApi(customerCluePoolCanAdd)
export const customerHasBusinessApi = productApi(customerVerify)
export const customerTransferToWhoApi = productApi(customerTransferToWho)
export const customerBackToPoolApi = productApi(customerBackToPool)
export const customerFollowApi = productApi(customerFollowAdd)
export const customerHelpersApi = productApi(customerHelperList)
export const customerHelperDelApi = productApi(customerHelperDel)
export const customerHelperSaveApi = productApi(customerHelperSave)
const ossImportApi = productApi(ossImport)
const customerCanAddApi = productApi(customerCanAdd)
const customerListByNameApi = productApi(customerListByName)
const businessListByNameApi = productApi(businessListByName)

const customerToImportantApi = productApi(customerToImportant)

const customerFollowImportantApi = productApi(customerFollowAddImportant) 
const customerFollowImportantUpdateApi = productApi(customerFollowEditImportant) 

const persistenceKey = 'customer/list'
const format = "YYYY-MM-DD HH:mm:ss"

export const handleHelperIds = (ids: any[], treeData: any) => {
    const arr = treeDataToFlatArr(treeData)
    const idMaps: any = {};
    const flatIds: any[] = [];
    ids?.forEach(v => {
        const item = v.length > 1 ? v[1] : v[0]
        flatIds.push(item)
    })
    flatIds?.forEach((v: any) => { 
        const key = v.includes('-') ? v.split('-')[0] : v
        idMaps[key] = []
    })
    Object.keys(idMaps).forEach(k => {
        if (flatIds.includes(k)) {
            idMaps[k] = ((arr.find(v => v.value === k)||{}).children||[]).map((v: any) => v.value)
        } else {
            idMaps[k] = flatIds.filter(v => v.startsWith(`${k}-`))
        }
    })
    return Object.keys(idMaps).map(key => {
        return {
            userId: key.slice(1),
            roleIds: idMaps[key].map((s: any) => (s.split('-')[1]||'').slice(1))
        }
    })
}

export const resverHandleHelperIds = (helperList: any[], treeData: any[]) => {
    const arr = treeDataToFlatArr(treeData)
    const idArr = (helperList||[]).map(({id, name, roles}) => {
       const { children } = (arr||[]).find(v => v.value === `s${id}`);
       if ((roles||[]).length === (children||[]).length) {
           return [`s${id}`]
       } else {
           return (roles||[]).map((v: any) => `s${id}-r${v.roleId}`)
       }
    })
    return (idArr||[])
}

export const HelperTagRender = ({treeData, ...rest}: any) => {
    const arr = treeDataToFlatArr(treeData)
    const { value, label } = rest
    const thatHelper = (arr.find(v => v.value === value.split('__')[0])||{})
    const calcNewLabel = value.includes('__') ? (thatHelper.label + `(${label})`) : label
    // const calcNewLabel = value.includes('__') ? thatHelper.label : label
    return <Tag {...rest}>{calcNewLabel}</Tag>
}

// 选择人员
export const AddStaff = ({fieldProps, disabledRule, children, ...rest}: any) => {
    return (<ModalForm<{name: string; company: string;}>  modalProps={{maskClosable: false}} layout="horizontal" width={600} {...rest}>
            <ProFormTreeSelect width="md" name="userId" label="人员" fieldProps={{showSearch: true, treeNodeFilterProp: 'title', ...fieldProps}} rules={[{required: true, message: '请选择人员'}]} />
            {children}
    </ModalForm>)
}

// 添加客户或线索 type: 1 客户 2 线索
interface AddCustomerOrClueButtonProps extends ButtonProps{
    params?: Record<string, any>;
    url?: string;
    btnText?: string;
}
export const AddCustomerOrClueButton = ({params, url, btnText, ...rest}: AddCustomerOrClueButtonProps) => {
    const history = useHistory()
    const onClick = async () => {
        const { data, msg } = await customerCanAddApi(params||{}, false)
        data && (url && history.push(url))
    }
    return <Button key="primary" type="primary" onClick={onClick} {...rest}>{btnText || '新建客户'}</Button>
}

// 转移客户
interface TransferCustomerProps extends ModalFormProps{
    selectedRowKeys?: Key[];
    onSuccess?: () => void
}
export const TransferCustomer = ({fieldProps, children, selectedRowKeys, onSuccess, ...rest}: any) => {
    const { deptWithUserTreeData } = useSelector((state: StateType) => state)
    const formRef = useRef<ProFormInstance>()
    const treeData = treeDataDisabledRules(deptWithUserTreeData as any, (v: any) => !v.whetherUser)

    const onVisibleChange = (visible: boolean) => {
        if (visible) {
            formRef.current?.resetFields()
        }
    }

    const onFinish = async (values: any) => {
        const { userId: uId } = values
        const userId = uId.slice(1)
        const { success } = await customerTransferToWhoApi({...values, userId, customerIds: selectedRowKeys}, true, onSuccess)
        return success
    }

    return (<ModalForm<TransferCustomerProps> modalProps={{maskClosable: false}} layout="horizontal" width={600} onFinish={onFinish} onVisibleChange={onVisibleChange} formRef={formRef} {...rest}>
            <ProFormTreeSelect width="md" name="userId" label="人员" fieldProps={{treeData, showSearch: true, treeNodeFilterProp: 'title', ...fieldProps}} rules={[{required: true, message: '请选择人员'}]} />
            {children}
    </ModalForm>)
}

// 批量导入
interface BatchImportProps extends ModalFormProps{
    showFields?: ('source' | 'poolId')[];
    params?: Record<string, any>;
    onSuccess?: () => void
}
export const BatchImport = ({children, onSuccess, params, showFields, ...rest}: BatchImportProps) => {
    const { userInfo, downLoadUrlMap } = useSelector((state: StateType) => state)
    const { user } = userInfo || {}
    const { id: userId } = user || {}
    const formRef = useRef<ProFormInstance>()
    const [sourceOptopns,] = useDictEnumHooks({dictId: 15 }, true)
    const [poolOptioins, ] = useCluesPoolDataHooks({ userId } as any, true)
    const [fileList, setFileLst] = useState<any[]>([])
    const customRequest = ({file}: any) => setFileLst([file])
    const onRemove = () => setFileLst([])
    const onVisibleChange = (visible: boolean) => {
        if (visible) {
            setFileLst([])
            formRef.current?.resetFields()
        }
    }
    const onFinish = async ({poolId, ...vals}: any) => {
        const obj = poolId ? { poolId: poolId * 1 } : {}
        const formData = toFormData({...vals, ...obj, ...(params||{})})
        const { success, data } = await ossImportApi(formData, false, onSuccess)
        const { info, file } = data || {}
        Modal.confirm({
            title: '处理成功',
            content: <div><p>{info}</p><p>错误文件下载地址:<a download={file} href={file}>{file}</a></p></div>,
            okText: '确认',
            cancelText: '取消',
        });
        return success
    }

    return (<ModalForm<BatchImportProps>  modalProps={{maskClosable: false}} layout="horizontal" width={600} labelCol={{span: 4}} onFinish={onFinish} onVisibleChange={onVisibleChange} formRef={formRef} {...rest}>
          {showFields?.includes('source') ? <ProFormSelect name="source" label="客户来源" options= {sourceOptopns} rules={[{required: true, message: '请选择客户来源'}]} /> : null}
         {showFields?.includes('poolId') ? <ProFormSelect name="poolId" label="线索池" options= {poolOptioins} rules={[{required: true, message: '请选择线索池'}]} /> : null}
         <ProFormUploadDragger max={1} label="文件导入" name="fileList" fieldProps={{ name: 'file', customRequest, onRemove, fileList, headers: {Authorization: getToken()}}} rules={[{required: true, message: '文件导入'}]}/>
         <a style={{float: 'right',position: 'relative', top: '-15px'}} download href={downLoadUrlMap["客户列表-批量导入客户"]}>模板下载</a> 
    </ModalForm>)
}

// 添加协同人
interface AddHelperProps extends ModalFormProps{
    type?: 1 | 2; // 1: 客户协同人, 2: 商机协同人 
    params?: any;
    selectedRowKeys?: Key[];
    onSuccess?: () => void
}
export const AddHelper = ({selectedRowKeys, onSuccess, params, type, ...rest}: AddHelperProps) => {
    const ownType = type || 1
    const formRef = useRef<ProFormInstance>()
    // const [treeData] = useDeptDataWithRoleHooks({}, true)
    const { deptWithUserTreeData } = useSelector((state: StateType) => state)
    const pmTreeData = treeDataDisabledRules(deptWithUserTreeData as any, (v: any) => !v.whetherUser)
    const onVisibleChange = (visible: boolean) => {
        if (visible) {
            formRef.current?.resetFields()
        }
    }
    const onFinish = async (values: any) => {
        const synergyIds = values['synergyIds'] === 0 ? [] : (values['synergyIds']||[]).map((v: string) => v.slice(1))
        // const items = handleHelperIds(values.userId, treeData)
        const submitParams = {type: ownType, customerIds: selectedRowKeys, synergyIds, ...(params||{})}
        const { success } = await customerHelperSaveApi(submitParams, true, onSuccess)
        return success
    }
    return (<ModalForm<AddHelperProps> modalProps={{maskClosable: false, destroyOnClose: true}} layout="horizontal" width={600} onFinish={onFinish} onVisibleChange={onVisibleChange} formRef={formRef} {...rest}>
        {/* <ProFormCascader 
            width="md" 
            name="userId" 
            label="协同人" 
            tooltip="请选择协同人，如，销售主管、售前等"
            fieldProps={{
                options: treeData, 
                multiple: true, 
                showSearch: true, 
                tagRender: (props: any) => <HelperTagRender {...props} treeData={treeData} />,
            }} 
            rules={[{required: true, message: '请选择人员'}]} 
        /> */}

        <ProFormCustTreeSelect
            name="synergyIds" 
            label="协同人" 
            tooltip="请选择协同人，如，销售主管、售前等"
            custOptions={custOptions}
            fieldProps={{
                treeData: pmTreeData, 
                showSearch: true,
                multiple: true, 
                treeCheckable: true, 
                treeNodeFilterProp: 'title', 
                style: { width: 328 }
            }} 
            rules={[{required: true, message: '请选择协同人'}]} 
        />
        { (selectedRowKeys||[]).length ? <HelperList selectedRowKeys={selectedRowKeys} type={ownType} /> : null }
   </ModalForm>)
}

interface HelperListProps extends Omit<ProTableProps<any, any>, 'type'> {
    selectedRowKeys?: Key[];
    type?: 1 | 2; // 1: 客户协同人, 2: 商机协同人 
}

const helperListRequest = async (params: any) => {
    const { data } = await customerHelpersApi(params, false)
    return {
        data: data||[],
        success: true,
        total: (data||[]).length
    }
}

// 客户-协同人列表
const HelperList = ({selectedRowKeys, type}: HelperListProps) => {
    const ownType = type || 1
    const ref = useRef<any>(null)
    const operate = async (type: string, record: any) => {
        if (type === '删除协同人') {
            const { customerId, userId } = record
            await customerHelperDelApi({type: ownType, userId, customerId}, true, reFush)
        }
    }
    const reFush = () => {
        ref.current?.clearSelected()
        ref.current?.reload()
    }
    const columns = helperListColumnsFn(operate)
    const params = {ids: selectedRowKeys, type: ownType}
    return <ProTable 
        columns={columns}
        rowKey="customerId" 
        search={false}
        options={false}
        params={params}
        request={helperListRequest}
        actionRef={ref}
    />
}


// 转为重点客户表单
const CustomerToImportantForm = ({selectedRowKeys, onSuccess, params, type, ...rest}: AddHelperProps) => {
    const ownType = type || 1
    const formRef = useRef<ProFormInstance>()
    const [treeData] = useDeptDataWithRoleHooks({}, true)
    const { deptWithUserTreeData } = useSelector((state: StateType) => state)
    const pmTreeData = treeDataDisabledRules(deptWithUserTreeData as any, (v: any) => !v.whetherUser)
    const onVisibleChange = (visible: boolean) => {
        if (visible) {
            formRef.current?.resetFields()
        }
    }
    const request = async (params: any) => {
        const { data } = await customerInfoApi(params, false)
        const { estimatedAnnualCashIncome, projectManager } = data
        return {
            projectManager: projectManager?.map((id: any) => `s${id}`),
            estimatedAnnualCashIncome
        }
    }
    const onFinish = async (values: any) => {
        const { synergyIds: synergy, projectManager: pmIds, ...restValues } = values
        // const items = handleHelperIds(values.userId||[], treeData)
        const synergyIds = synergy === 0 ? [] : (synergy||[]).map((v: string) => v.slice(1))
        // const { data } = await helperListRequest({ids: selectedRowKeys, type: ownType})
        // if ( !synergyIds.length && !data.length) {
        //     message.warning('请选择协同人')
        //     return false
        // }
        const projectManager = pmIds === 0 ? [] : (pmIds||[]).map((v: string) => v.slice(1))
        const submitParams = {type: ownType, customerIds: selectedRowKeys, projectManager, synergyIds, ...restValues, ...(params||{})}
        // console.log('------submitParams', pmIds, submitParams)
        const { success } = await customerToImportantApi(submitParams, true, onSuccess)
        return success
    }
    
    return (<ModalForm<AddHelperProps> 
        layout="horizontal"
        width={600} 
        onFinish={onFinish} 
        onVisibleChange={onVisibleChange} 
        formRef={formRef} 
        title="转为重点客户"
        labelCol={{span: 6}} 
        params={{id: selectedRowKeys?.[0]}}
        request={request}
        {...rest}
        >
        <Alert 
            message={<>
              <ul style={{listStyle: 'none'}}>
                <li>说明：*客户一旦被转为重点客户，将不能再转为普通客户。</li>
                <li>*转为重点客户请务必添加协同人（项目组关键成员，如“销售主管、售前”）。</li>
                <li>*转为重点客户后，请在客户详情界面完善“客户业务调研表、决策链地图、商务地图、业务地图、跟进记录”信息。</li>
              </ul>
            </>} 
            type="warning" 
            style={{marginBottom: 20}}
        />
        <ProFormMoney 
            width="md" 
            name="estimatedAnnualCashIncome" 
            max={999999999} 
            min={0} 
            label="预计年Cash收入" 
            tooltip="按自然年填写，自然年指每年的1月1号到12月31号（该客户一整年的预估年消费Cash）"
            rules={[{ required: true, message: '请输入预计年Cash收入',}]} 
            placeholder="请输入"
            fieldProps={{ 
                formatter: value => `¥ ${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                parser: (value: any) => value.replace(/\¥\s?|(,*)/g, ''),
                defaultValue: 0,
                addonAfter: '元'
            }}  
        />
        {/* <ProFormTreeSelect 
            width="md" 
            name="projectManager" 
            label="项目经理" 
            fieldProps={{
                treeData: pmTreeData, 
                showSearch: true,
                multiple: true, 
                treeCheckable: true, 
                treeNodeFilterProp: 'title', 
                style: { width: 328 }
            }} 
            rules={[{required: false, message: '请选择项目经理'}]} 
        /> */}
        <ProFormCustTreeSelect 
            name="projectManager" 
            label="项目经理" 
            custOptions={custOptions}
            fieldProps={{
                treeData: pmTreeData, 
                showSearch: true,
                multiple: true, 
                treeCheckable: true, 
                treeNodeFilterProp: 'title', 
                style: { width: 328 }
            }} 
            rules={[{required: true, message: '请选择项目经理'}]} 
        />
        {/* <ProFormCascader 
            width="md" 
            name="userId" 
            label="协同人" 
            tooltip="请选择协同人，如，销售主管、售前等"
            fieldProps={{
                options: treeData, 
                multiple: true, 
                showSearch: true, 
                tagRender: (props: any) => <HelperTagRender {...props} treeData={treeData} />,
            }} 
            rules={[{required: false, message: '请选择协同人'}]} 
        /> */}
        <ProFormCustTreeSelect 
            name="synergyIds" 
            label="协同人" 
            tooltip="请选择协同人，如，销售主管、售前等"
            custOptions={custOptions}
            fieldProps={{
                treeData: pmTreeData, 
                showSearch: true,
                multiple: true, 
                treeCheckable: true, 
                treeNodeFilterProp: 'title', 
                style: { width: 328 }
            }} 
            rules={[{required: false, message: '请选择协同人'}]} 
        />
        { (selectedRowKeys||[]).length ? <HelperList selectedRowKeys={selectedRowKeys} type={ownType} /> : null }
   </ModalForm>)
}

// 转为重点客户
interface CustomerToImportantProps extends Omit<AddHelperProps, 'type'> {
    isCanToImportantParams?: {};
    disabled?: boolean;
    selectedRows?: any[]
}
export const CustomerToImportant = ({selectedRowKeys, selectedRows, disabled, isCanToImportantParams, onSuccess: callBack}: CustomerToImportantProps) => {
    const history = useHistory()
    const calcDisabled = () => !(selectedRowKeys?.length === 1)
    const customerId = selectedRowKeys ? selectedRowKeys[0] : undefined
    const [modalProps, setModalProps] = useState({visible: false})
    const [ target ] = selectedRows||[] as any
    const onClick = async () => {
        if (disabled) {
            Modal.confirm({
                title: '温馨提示',
                content: <>此客户已为重点客户, 不可重复操作 </>,
                cancelText: '取消',
            })
            return
        }
        const params = {customerId, ...isCanToImportantParams }
        const { data } = await customerHasBusinessApi(params, false)
        !data ? Modal.confirm({
            title: '温馨提示',
            content: <>普通客户转为重点客户前，请先<a 
                        key="update" 
                        onClick={() => {
                            Modal.destroyAll()
                            history.push(`/customer/businessAdd?${stringify({customerName: target?.customerName, customerId: target?.id, routeName: '创建商机', fromPath: '/customer/list'})}`)
                        }}>创建商机</a>。
            </>,
            okText: '前往',
            cancelText: '取消',
            onOk: async() => {
                history.push(`/customer/businessAdd?${stringify({customerName: target?.customerName, customerId: target?.id, routeName: '创建商机', fromPath: '/customer/list'})}`)
            },
        }) : setModalProps({visible: true})
    }

    const onSuccess = () => {
        setModalProps({visible: false})
        callBack?.()
    }

    return (<>
        {/* <Button onClick={onClick} disabled={ disabled !== undefined ? disabled : calcDisabled()} type="primary">转为重点客户</Button> */}
        <Button onClick={onClick} disabled={ calcDisabled() } type="primary">转为重点客户</Button>
        <CustomerToImportantForm 
            modalProps={{onCancel: () => setModalProps({visible: false}), maskClosable: false}} 
            selectedRowKeys={selectedRowKeys}
            {...modalProps} 
            onSuccess={onSuccess}
        />
    </>)
}



// 客户移入线索池
interface CustomerToCluePoolButtonProps extends ButtonProps{
    params?: Record<string, any>;
    onSuccess?: () => void;
}

export const CustomerToCluePoolButton = ({children, params, onSuccess, ...rest}: CustomerToCluePoolButtonProps) => {
    const onClick = async () => {
        const { data, msg } = await customerCluePoolCanAddApi(params, false)
        data ? Modal.confirm({
            title: '确认要移入线索池吗?',
            content: '客户转移到线索池后，将属于公共资源，原归属人不能再维护跟进和更新此客户数据。',
            okText: '确认',
            cancelText: '取消',
            onOk: async() => {
                await customerBackToPoolApi(params, true, onSuccess)
            },
        }) : message.error(msg)
    }
    return <Button key="btn3" onClick={onClick} {...rest}>{children}</Button>
}



// 新建/编辑 跟进
/**
 * 1. 如果组件传入了 customerId || customerName  则 客户名称 只读
 * 2. 如果组件传入了 [商机ID] || [商机标题]  则 商机标题 只读
*/
export interface AddFollowRecordProps extends ModalFormProps{
    // isImportCustomer?: boolean;  // 是否是重点客户
    customerId?: string | number;
    customerName?: string;
    businessOppId?: string | number;
    businessOpportunityTitle?: string;
    noCheck?: boolean;  // 外层是否 做了 能否跟进 的判断：true  不做； false 做； 不做的话 商机标题 字段 非必填
    params?: {
        id?: React.Key  // 跟进id
    };
    onSuccess?: () => void;
}
async function getFollowUpByBusinessIdApi(params: any) {
    const { data } = await getFollowUpByBusinessId(params) as any
            const { 
                followUpTime,
                synergyId,
                projectStage,
                accountRegister,
                followUpContent,
                stickingPoint,
                helpPoint,
                nextStepContent,
                createGroup,
                makeSuccess,
                poc,
                pocTest,
                procurementMethod,
                orderMethod
            } = data || {}
            return {
                followUpTime,
                synergyId: synergyId?.map((id: any) => `s${id}`),
                followUpContent,
                stickingPoint,
                helpPoint,
                nextStepContent,
                projectStage,
                accountRegister,
                createGroup,
                makeSuccess,
                pocTest,
                poc,
                procurementMethod,
                orderMethod
            }
}

// 跟进时 重点客户的 hook
type UseImportCustomerFollowProps = {
    customerId?: any
}
const useImportCustomerFollow = ({customerId}: UseImportCustomerFollowProps) => {
    const [isImportCustomer, setIsImportCustomer] = useState<boolean>(true)  // 检测是否是重点客户
    const getCustomerInfoApi = async (params: UseImportCustomerFollowProps) => {
        const { data } = await customerGetInfoById({ id: params?.customerId }) as any
        const { isEmphasisCustomer } = data || {}
        setIsImportCustomer(!!isEmphasisCustomer)
        return data
    }

    useEffect(() => {
        customerId && getCustomerInfoApi({customerId})
    }, [customerId])

    return {
        isImportCustomer,
        setIsImportCustomer,
        getCustomerInfoApi,
    }
} 

export const AddFollowRecord = ({customerId, customerName, businessOppId, businessOpportunityTitle, params, noCheck, onSuccess, ...rest}: AddFollowRecordProps) => {
    const [contactsOptions, , getApi] = useCantactsByCustomerIdHooks({}, false)
    const [followUpMethodOptions ] = useDictEnumHooks({dictId: 17}, true)
    const [salesStageOptions,] = useDictEnumHooks({dictId: 20}, true)
    const [chatGroupOptions,] = useDictEnumHooks({dictId: 266}, true)
    const [purchaseModeOptions,] = useDictEnumHooks({dictId: 267}, true)
    const [orderModeOptions,] = useDictEnumHooks({dictId: 279}, true)
    const [customerOptions, setCustomerOptions] = useState<any>([])  // 客户列表
    const [businessOpportunityOptions, setBusinessOpportunityOptions] = useState<any>([])  // 商机列表
    const [warning, setWarning] = useState<boolean>(false)
    const [visibleCount, setVisibleCount] = useState<number>(0)
    
    // const [customerId, setCustomerId ] = useState(originCustomerId)

    // const [treeData, , getUserRoleApi] = useDeptDataWithRoleHooks({}, true) // 协同人数据
    const { deptWithUserTreeData } = useSelector((state: StateType) => state)
    const treeData = treeDataDisabledRules(deptWithUserTreeData as any, (v: any) => !v.whetherUser)

    // const [followUpFeedbackOptions ] = useDictEnumHooks({dictId: 18,}, true)
    // const [followUpFeedbackOptions, , getFollowUpFeedbackOptionsApi] = useCommonApiHooks({url: `${BASEURL}/taskCenter/getFollowupOptions`, method: 'GET'}, {customerId}, false)
    const formRef = useRef<ProFormInstance>()

    // 重点客户跟进逻辑
    const { isImportCustomer, getCustomerInfoApi } = useImportCustomerFollow({customerId})

    // 模糊搜索客户列表
    const onSearchCustomer = async (customerName: string, ownedByMe: boolean = true) => {
        await customerListByNameApi({key: customerName, ownedByMe}, false, (data: any[]) => {
           const newOptions = (data||[]).map(({id: value, customerName: label}) => ({label, value}))
           setCustomerOptions(newOptions as any)
        })
    }

    // 模糊搜索商机列表
    const onSearchBusinessOpportunity = async (businessOpportunityTitle: string, originCustomerId: any = customerId, ownedByMe: boolean = true) => {
        await businessListByNameApi({name: businessOpportunityTitle, customerId: originCustomerId, ownedByMe}, false, (data: any[]) => {
           const newOptions = (data||[]).map(({id: value, businessOpportunityTitle: label}) => ({label, value}))
           setBusinessOpportunityOptions(newOptions as any)
        })
    }

    const onFinish = async (values: any) => {
        // const whichApi = params?.id ? customerFollowImportantApi : customerFollowApi
        const whichApi = customerFollowApi
        const synergysArr = values.synergyId
        const submitParams = {
            ...values, 
            customerId: values.customerId ? values.customerId : customerId, 
            businessOppId: values.businessOppId ? values.businessOppId : businessOppId, 
            ...(params||{}),
            followUpId: params?.id,
            // preSalesId: values.preSalesId?.slice(1),
            // salesId: values.salesId?.slice(1),
            // synergyId: values.synergyId?.map((v: string) => (v||'').slice(1)),
            synergyId: Array.isArray(synergysArr) ? synergysArr.filter(Boolean)?.map((id: string) => id.slice(1)) : [], 
        }
        const { success } = await whichApi(submitParams, false, onSuccess)
        return success
    }

    const onValuesChange = async (values: any, allValues: any) => {
        if ('customerId' in values) {
            getApi({customerId: values?.customerId, pageCurrent: 1, pageSize: 1000})
            formRef.current?.setFieldsValue({businessOppId: undefined})
            onSearchBusinessOpportunity('', values?.customerId)
            getCustomerInfoApi(values)
        } else if ('businessOppId' in values) {
            const data = await getFollowUpByBusinessIdApi({businessId: values?.businessOppId}) as any
            formRef.current?.setFieldsValue(data)
        } else if ('followUpContent' in values) {
            setWarning(values.followUpContent?.length < 51)
        }
    }

    const request = async (params: any) => {
        customerId && getApi({customerId, pageCurrent: 1, pageSize: 1000})
        // const res = await rest.request?.(params, null)
        if (params?.id) {
            const { data } = await getFollowUpById(params) as any
            const {
                followUpMethod,
                followUpTime,
                synergyId,
                contactsId,
                projectStage,
                followUpContent,
                stickingPoint,
                helpPoint,
                nextStepContent,
                accountRegister,
                createGroup,
                makeSuccess,
                pocTest,
                poc,
                procurementMethod,
                orderMethod,
                followUpType,
                businessId: businessOppId,
                hasBusiness
            } = data || {}
            return {
                customerId,
                businessOppId: businessOppId ? parseInt(businessOppId as any) : businessOppId,
                followUpMethod,
                followUpTime,
                synergyId: synergyId && Array.isArray(synergyId) && synergyId.length ? synergyId.map((v: string) => `s${v}`) : [0],
                contactsId,
                projectStage,
                followUpContent,
                stickingPoint,
                helpPoint,
                nextStepContent,
                accountRegister,
                createGroup,
                makeSuccess,
                pocTest,
                poc,
                procurementMethod,
                orderMethod,
                followUpType,
                hasBusiness
            }
        } else if (businessOppId) {
            const data = await getFollowUpByBusinessIdApi({businessId: businessOppId}) as any
            return {
                customerId,
                businessOppId: businessOppId ? parseInt(businessOppId as any) : businessOppId,
               ...data
            }
        } else {
            return {
                customerId,
                businessOppId: businessOppId ? parseInt(businessOppId as any) : businessOppId,
            }
        }
        
    }

    const calcDisabled = () => !!(customerId || customerName)
    const calcBusinessOppDisabled = () => !!(businessOppId) // todo
    const disabledDate = (current: any) => current && current >= moment().subtract (0,"days").endOf('day')

    const onVisibleChange = async (visible: boolean) => {
        if (visible) {
            setVisibleCount(visibleCount+1)
            setBusinessOpportunityOptions([])
            if (customerId) {
                getApi({customerId, pageCurrent: 1, pageSize: 1000})
                setCustomerOptions([{label: customerName, value: customerId}])
                onSearchBusinessOpportunity('', customerId)
            } 
            if (businessOppId) {
                setBusinessOpportunityOptions([{value: businessOppId, label: businessOpportunityTitle}])
            }
        }
    }

    return (<ModalForm<AddFollowRecordProps> 
                modalProps={{maskClosable: false, destroyOnClose: true}} 
                layout="horizontal" 
                width={660} 
                labelCol={{span: 6}} 
                onFinish={onFinish} 
                onVisibleChange={onVisibleChange} 
                onValuesChange={onValuesChange}
                submitter={{
                    render: (props, defaultDoms) => {
                        return [
                        //   <AddContact key="添加联系人" title="添加联系人" customerId={customerId} onSuccess={() => getApi({customerId, pageCurrent: 1, pageSize: 1000})} trigger={<Button key="addContact" style={{marginLeft: 10}}>新建联系人</Button>} />,
                          ...defaultDoms,
                        ];
                    },
                }}
                formRef={formRef} 
                key={`${customerId}-${businessOppId}`}  // 防止缓存
                params={{...params, customerId, businessOppId, visibleCount}}
                initialValues={{followUpType: 2}}
                {...rest}
                request={ request }
                >
                    <ProFormSelect 
                        width="md" 
                        name="customerId" 
                        label="客户名称" 
                        disabled={calcDisabled()} 
                        options={customerOptions} 
                        fieldProps={{
                            showSearch: true, 
                            filterOption: false, 
                            onSearch: debounce(onSearchCustomer, 600)
                        }} 
                        rules={[{ required: false, message: '请选择关联客户',}]} 
                        placeholder="请输入客户名称查询" 
                    />
                    {/* <ProFormRadio.Group
                        width="md" 
                        name="followUpType" 
                        label="跟进类型" 
                        tooltip="若跟进类型是商机，则需要关联商机标题；若跟进类型是客户，不需要关联商机标题，直接填写跟进信息"
                        options={followUpTypeOptions} 
                        rules={[{required: true, message: '请选择跟进类型'}]} 
                    /> */}
                    {
                        isImportCustomer ? <ProFormRadio.Group
                                                width="md" 
                                                name="hasBusiness" 
                                                label="有无商机" 
                                                // tooltip="若跟进类型是商机，则需要关联商机标题；若跟进类型是客户，不需要关联商机标题，直接填写跟进信息"
                                                options={hasBusinessOptions} 
                                                rules={[{required: true, message: '请选择有无商机'}]} 
                                            /> : null
                    }
                    
                    <ProFormDependency name={['customerId', 'hasBusiness']}>
                        {({customerId, hasBusiness}) => {
                            const search = (customerId: any) => (businessOpportunityTitle: any) => onSearchBusinessOpportunity(businessOpportunityTitle, customerId)
                            return hasBusiness === 1 ? (<ProFormSelect 
                                width="md" 
                                name="businessOppId" 
                                label="商机标题" 
                                disabled={calcBusinessOppDisabled()} 
                                options={businessOpportunityOptions} 
                                fieldProps={{
                                    showSearch: true, 
                                    filterOption: false, 
                                    onSearch: debounce(search(customerId), 600)
                                }} 
                                rules={[{ required: !noCheck, message: '请选择商机标题',}]} placeholder="请输入商机标题查询" 
                            />) : null
                        }}
                    </ProFormDependency>
                    <Space.Compact block>
                        <ProFormSelect 
                            width="md" 
                            name="contactsId" 
                            label="本次沟通联系人" 
                            mode='multiple' 
                            formItemProps={{style: {width: '100%'}}} 
                            options={(contactsOptions||[]).map(({label, positionStr, ...rest}: any) => ({...rest, label: `${label}(${positionStr})`}))} 
                            rules={[{required: true, message: '请选择联系人'}]} 
                        />
                        <ProFormDependency name={['customerId']}>
                            {({customerId}) => (<AddContact 
                                                    key="添加联系人" 
                                                    title="添加联系人" 
                                                    customerId={customerId} 
                                                    onSuccess={() => getApi({customerId, pageCurrent: 1, pageSize: 1000})} 
                                                    trigger={<Button key="addContact" type="link" style={{position: 'absolute', right: '60px'}}>新建联系人</Button>} 
                                                />)}
                        </ProFormDependency>
                    </Space.Compact>
                    <ProFormSelect 
                        width="md" 
                        name="followUpMethod" 
                        label="跟进方式" 
                        options={followUpMethodOptions} 
                        rules={[{required: true, message: '请选择跟进方式'}]} 
                    />
                    {/* <ProFormSelect 
                        width="md" 
                        name="businessOppId" 
                        label="商机标题" 
                        disabled={calcBusinessOppDisabled()} 
                        options={businessOpportunityOptions} 
                        fieldProps={{
                            showSearch: true, 
                            filterOption: false, 
                            onSearch: debounce(onSearchBusinessOpportunity, 600)
                        }} 
                        rules={[{ required: false, message: '请选择商机标题',}]} placeholder="请输入商机标题查询" 
                    /> */}
                    <ProFormDatePicker 
                        width="md" 
                        name="followUpTime" 
                        label="本次沟通时间" 
                        fieldProps={{
                            disabledDate
                        }}
                        rules={[{required: true, message: '请选择本次沟通时间'}]} 
                    />
                    {/* <ProFormTreeSelect 
                        width="md" 
                        name="synergyId" 
                        label="本次跟进协同人" 
                        fieldProps={{
                            treeData, 
                            maxTagCount: 4, 
                            treeCheckable: true, 
                            showSearch: true, 
                            treeNodeFilterProp: 'title', 
                            style: {width: 328}
                        }}
                        rules={[{ required: true, message: '请选择本次跟进协同人',}]} 
                        placeholder="请选择" 
                    /> */}
                    {
                        isImportCustomer ? <ProFormCustTreeSelect 
                                                name="synergyId" 
                                                label="本次跟进协同人" 
                                                custOptions={custOptions}
                                                fieldProps={{
                                                    treeData, 
                                                    maxTagCount: 4, 
                                                    showSearch: true,
                                                    multiple: true, 
                                                    treeCheckable: true, 
                                                    treeNodeFilterProp: 'title', 
                                                    placeholder: '请选择本次跟进协同人',
                                                    style: { width: 328 }
                                                }} 
                                                rules={[{ required: true, message: '请选择本次跟进协同人',}]} 
                                            /> : null
                    }
                    
                    {/* <ProFormSelect 
                        width="md" 
                        name="projectStage" 
                        label={<>项目阶段  <SalesStageToolTip trigger={<Icon type="icon-ziyuan" />} /></>} 
                        options={salesStageOptions} 
                        rules={[{ required: true, message: '请选择项目阶段',}]} 
                        placeholder="请选择" 
                    /> */}
                    <ProFormTextArea 
                        width="md" 
                        name="followUpContent" 
                        label="跟进内容" 
                        // formItemProps={ warning ? {
                        //     validateStatus: 'warning',
                        //     help: '跟进内容少于50个字，将被视为无效跟进'
                        // } : undefined}
                        fieldProps={{
                            maxLength: 1000, 
                            showCount: true, 
                            placeholder: '本次沟通要点总结罗列，客户痛点，项目机会点等。',
                        }} 
                        rules={[{required: true, message: '请填写跟进内容'}, {max: 1000, type:'string', message: '1000字以内',}]} 
                    />
                    {
                        isImportCustomer ? <>
                                                <ProFormTextArea 
                                                    width="md" 
                                                    name="stickingPoint" 
                                                    fieldProps={{
                                                        maxLength: 1000,
                                                        showCount: true, 
                                                        placeholder: '请描述具体'
                                                    }} 
                                                    label="待突破的卡点" 
                                                    rules={[{ required: true, message: '请填写待突破的卡点',}]} 
                                                    placeholder="请具体描述" 
                                                />
                                                <ProFormTextArea 
                                                    width="md" 
                                                    name="helpPoint" 
                                                    label="求助点" 
                                                    tooltip={`请描述求助点，例如：需要高层支持、需要架构师支持、需要研发支持、需要厂家支持、需要资金垫付支持等`} 
                                                    fieldProps={{
                                                        maxLength: 1000, 
                                                        showCount: true
                                                    }} 
                                                    rules={[{required: false, message: '请填写求助点'}, {max: 1000, type:'string', message: '300字以内'}]} 
                                                    placeholder={`请描述求助点，例如：需要高层支持、需要架构师支持、需要研发支持、需要厂家支持、需要资金垫付支持等`} 
                                                />
                        </> : null
                    }
                    <ProFormTextArea 
                        width="md" 
                        name="nextStepContent" 
                        label="下一步动作" 
                        tooltip={`请输入下一步行动内容，按照“事件-责任人-完成时间”格式填写，例如：需要客户做什么？需要我方准备什么？`} 
                        fieldProps={{
                            maxLength: 1000, 
                            showCount: true, 
                            placeholder: '请输入下一步行动内容，按照“事件-责任人-完成时间”格式填写，例如：需要客户做什么？需要我方准备什么？'
                        }} 
                        rules={[{required: true, message: '请填写下一步动作'}, {max: 1000, type:'string', message: '1000字以内'}]} 
                    />
                    {
                        isImportCustomer ? <>
                                                <ProFormRadio.Group width="md" name="accountRegister" label="账号是否注册" options={yesNoOptions} rules={[{ required: true, message: '请选择账号是否注册',}]} placeholder="请选择" />
                                                <ProFormSelect 
                                                    width="md" 
                                                    name="createGroup" 
                                                    label="建立沟通群" 
                                                    options={chatGroupOptions} 
                                                    // mode='multiple'  // bianchen
                                                    rules={[{ required: true, message: '请选择沟通群',}]} 
                                                    placeholder="请选择" 
                                                />
                                                {/* <ProFormRadio.Group width="md" name="makeSuccess" label="是否80%以上概率落单" options={yesNoOptions} rules={[{ required: true, message: '请选择是否80%以上概率落单',}]} placeholder="请选择" /> */}
                                                {/* <ProFormRadio.Group width="md" name="pocTest" label="是否poc测试"  options={yesNoOptions} rules={[{ required: true, message: '请选择是否poc测试',}]} placeholder="请选择" /> */}
                                                <ProFormText width="md" name="poc" label="是否poc测试" rules={[{ required: true, message: '请填写是否poc测试',}]} placeholder="若已进行poc测试，请描述测试情况" />
                                                <ProFormSelect width="md" name="procurementMethod" label="采购方式" options={purchaseModeOptions} rules={[{ required: true, message: '请选择采购方式',}]} placeholder="请选择" />
                        </> : null
                    }
                    {/* <ProFormSelect width="md" name="orderMethod" label="下单方式" options={orderModeOptions} rules={[{ required: true, message: '请选择下单方式',}]} placeholder="请选择" /> */}

                    {/* 反馈说禁用的不显示, 补充: 老板又说，不要跟进反馈了 */}
                    {/* <ProFormSelect width="md" name="followUpFeedback" options={(followUpFeedbackOptions||[]).map(({status, optionName: label, optionId: value}: any) => ({status, label, value})).filter((v: any) => v.status !== '0')} label="跟进反馈" rules={[{required: true, message: '请选择跟进反馈'}]} /> */}
                    
                    {/* 老板说去掉 2023.2.20 日需求变更，新增 下一步动作 */}
                    {/* <ProFormDateTimePicker width="md" name="nextFollowTime" label="下次跟进" rules={[{required: false, message: '请选择下次跟进时间'}]} /> */}
                    

                    {/* 以下为重要项目的跟进 */}
                    {
                        // isImportCustomer ? <>
                        //         <ProFormCascader width="md" name="salesId" label="销售" fieldProps={{options: treeData, multiple: true, showSearch: true, tagRender: (props: any) => <HelperTagRender {...props} treeData={treeData} />, style: {width: 328}}} rules={[{ required: true, message: '请选择销售',}]} placeholder="请选择" />
                        //         <ProFormCascader width="md" name="preSalesId" label="沟通参与售前" fieldProps={{options: treeData, multiple: true, showSearch: true, tagRender: (props: any) => <HelperTagRender {...props} treeData={treeData} />, style: {width: 328}}} rules={[{ required: true, message: '请选择销售',}]} placeholder="请选择" />
                        //         <ProFormTreeSelect width="md" name="salesId" label="销售" fieldProps={{treeData, showSearch: true, treeNodeFilterProp: 'title', style: { width: 328 }}} rules={[{required: true, message: '请选择'}]} />
                        //         <ProFormTreeSelect width="md" name="preSalesId" label="沟通参与售前" fieldProps={{treeData, showSearch: true, treeNodeFilterProp: 'title', style: { width: 328 }}} rules={[{required: true, message: '请选择'}]} />
                        //         <ProFormSelect width="md" name="stage" label="项目阶段" options={salesStageOptions} rules={[{ required: true, message: '请选择项目阶段',}]} placeholder="请选择" />
                        //         <ProFormDatePicker width="md" name="predictFloorTime" label="预计落地时间" fieldProps={{disabledDate, showTime: true, format}} rules={[{ required: true, message: '请选择预计落地时间',}]} placeholder="请选择" />
                        //         <ProFormText width="md" name="pocTest" fieldProps={{maxLength: 100}} label="POC测试情况" rules={[{ required: false, message: '请填写POC测试情况',}]} placeholder="请填写" />
                        //         <ProFormText width="md" name="stickingPoint" fieldProps={{maxLength: 100}} label="待突破的卡点" rules={[{ required: false, message: '请填写待突破的卡点',}]} placeholder="请填写" />
                        //         <ProFormTextArea width="md" name="helpPoint" label="求助点" fieldProps={{maxLength: 300, showCount: true}} rules={[{required: false, message: '请填写求助点'}, {max: 300, type:'string', message: '300字以内'}]} placeholder={`请描述求助点，例如：需要高层支持、需要架构师支持、需要研发支持、需要厂家支持、需要资金垫付支持等`} />
                        //     </> : null
                    }
                    
        </ModalForm>)
}

// 客户回流机制 文案提示
interface CustomerBackflowAlertProps extends AlertProps {
    params?: any
}
export const CustomerBackflowAlert = ({params, ...rest}: CustomerBackflowAlertProps) => {
    const { data } = useRequest(() => getSystemRuleNotFollowupDay(params)) as any
    const desc = getCustomerReturnDesc(data?.data as any)
    return desc ? <Alert message={desc} type="warning" showIcon closable {...rest} /> : null
}

export default () => {
    const { sysNotFollowDay } = useSelector((state: StateType) => state)
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    const [selectedRows, setSelectedRows] = useState<React.Key[]>([])
    const [view, setView] = useState<string>('')
    const [scope, setScope] = useState<string>('')
    const [dynamicFields, setDynamicFields] = useState<any>({})
    const [summary, setSummary] = useState({estimatedAnnualCashIncome: 0, total: 1 })

    const formRef = useRef<ProFormInstance>()
    const ref = useRef<ActionType>(null);
    const [addFollowProps, setAddFollowProps] = useState<AddFollowRecordProps>({title: '新建跟进', visible: false, customerId: undefined, onSuccess: undefined})

    const [, hasAuthCode] = useHasAuthCode()
    const reFush = () => {
        setTimeout(() => {
            // @ts-ignore
            ref.current?.clearSelected()
            ref.current?.reload()
        }, 1000)
    }

    const {
        delAction,
        attentionAction,
        returnOwnerAction,
        returnBackAction,
        exprotUserCustomerAndAccount,
        isCanaddFollowAction
    } = useCustomerListOperate(reFush)

    const getListApi = async (params: any, sorter: any, filter: any) => {
        const { data } = await getApi({...params, sorter}, false)
        const { values, total, dynamicFields, estimatedAnnualCashIncome } = data || {}
        setDynamicFields(dynamicFields)
        setSummary({estimatedAnnualCashIncome, total})
        return {
            data: values || [],
            total,
            success: true,
        }
    }

    const customerViewOnChange = (val: any, type: any) => type === 'view' ? setView(val) : setScope(val)
    
// 假设的request函数，它返回一个Promise
const request = (options: { url: string }) => {
    return new Promise((resolve, reject) => {
        // 这里应该是实际的网络请求逻辑
        // 但为了简化，我们直接模拟一个成功的响应
        setTimeout(() => {
            resolve({ file: 'http://example.com/file.pdf' });
        }, 1000);
    });
};


    const operate = async (type: OperateActionType, record?: TableListItem | null, values?: any) => {
        const {id: customerId, isAttention, customerName, hasBusiness} = record || {}
        const ids = customerId ? [customerId] : selectedRowKeys
        switch (type) {
            case '关注':
                attentionAction({id: customerId, type: isAttention ? 0 : 1})
                break;
            case '批量删除':
                delAction({ids})
                break;
            case '转回个人线索':
                returnOwnerAction({ids})
                break;
            case '退回普通客户':
                returnBackAction({ids})
                break;
            case '客户帐号归属导出':
                exprotUserCustomerAndAccount()
                break;
            case '添加跟进':
                const ok = await isCanaddFollowAction({customerName, customerId, type: 2})
                ok && setAddFollowProps({
                    ...addFollowProps,
                    title: type,
                    visible: true,
                    customerId,
                    customerName,
                    onSuccess: () => {
                        reFush()
                        setAddFollowProps({...addFollowProps, visible: false})
                    }
                })
                break;
        }
    }

    const columns = columnsFn(operate, dynamicFields)
    const params = { view, scope }

    const getQueryParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            // @ts-ignore
            ...formRef.current?.getFieldsFormatValue(),
            fields,
            ...params
        })
    }

    const batchParams = () => {
        const json = getCheckedColumnsStateByColumns(columns, persistenceKey)
        const fields = Object.keys(json).filter(key => (json[key].show && !['option'].includes(key)))
        return ({
            ids: selectedRowKeys,
            fields,
            ...params
        })
    }

    const calcDisabled = () => !selectedRowKeys.length
    const calcTransformImportDisadled = () => !((selectedRowKeys?.length === 1) && selectedRows?.some((v: any) => !v.isEmphasisCustomer))


    return <div>
        <div style={{boxSizing: 'border-box'}}>
            <ViewScope 
                viewOptions={customerViewOptions}
                scopeOptions={customerScopeOptions}
                onChange={customerViewOnChange}
            />
            <ProTableWithFormSetting<TableListItem>
                // scroll={{x: 2800}}
                scroll={{x: summary?.total > 5 ? 'max-content' : 2800}}
                sticky
                // headerTitle={<h5 style={{fontSize: '14px', margin: 0}}>预计年Cash收入 <span style={{color: 'red'}}>{summary?.estimatedAnnualCashIncome||0}</span> （汇总列表查询结果）</h5>}
                columns={columns}
                params={params}
                request={getListApi}
                options={{reload: false, density: false}}
                toolbar={{
                    actions: [
                       hasAuthCode(2006) ? <AddCustomerOrClueButton key="新建客户" params={{type: 1}} url={`/customer/add?${stringify({sourceType: 0, routeName: '新建客户', fromPath: '/customer/list'})}`} /> : null,
                       hasAuthCode(2489) ? <CustomerToImportant key="转重点" title="转重点" selectedRowKeys={selectedRowKeys} selectedRows={selectedRows} disabled={calcTransformImportDisadled()} isCanToImportantParams={{type: 1}} params={{type: 1}} onSuccess={reFush} /> : null,
                       hasAuthCode(2043) ? <AddHelper key="bnt1" title="添加协同人" selectedRowKeys={selectedRowKeys} trigger={<Button disabled={calcDisabled()}>添加协同人</Button>} onSuccess={reFush} /> : null,
                    //    hasAuthCode(2023) ? <TransferCustomer key="bnt2" title="转移客户" selectedRowKeys={selectedRowKeys} trigger={<Button disabled={calcDisabled()}>转移客户</Button>} onSuccess={reFush} /> : null,
                    //    hasAuthCode(2112) ? <CustomerToCluePoolButton key="btn3" disabled={calcDisabled()} params={{customerIds: selectedRowKeys, type: 1}} onSuccess={reFush}>移入线索池</CustomerToCluePoolButton> : null,
                       
                    //    <Dropdown key="more" placement="bottomRight" arrow overlay={<Menu>
                    //         { hasAuthCode(2028) ? <Menu.Item key="btn1"><BatchImport  key="bnt4" title="导入客户" params={{type: 2}} showFields={['source']} trigger={<a>导入客户</a>} onSuccess={reFush} /></Menu.Item> : null }
                    //         {/* { hasAuthCode(-1) ? <Menu.Item key="btn2"><ImportButton action={`${BASEURL}/followup/importFollowups`} params={{type: 1}} templateUrl='https://jns-crm.oss-cn-hangzhou.aliyuncs.com/B12756D58B344904AAD57A727304A417_导入跟进记录-客户列表&我的线索.xlsx' title="导入跟进" trigger={<a>导入跟进</a>}></ImportButton></Menu.Item> : null } */}
                    //     </Menu>}>
                    //         <Button>批量导入</Button>
                    //     </Dropdown>,
                    //    hasAuthCode(2026) ? <ExportButton key="btn5" disabled={calcDisabled()} param={{ids: selectedRowKeys}} action={`${BASEURL}/exportCustomers`}></ExportButton> : null,
                    //    hasAuthCode(2110) ? <Button key="btn6" disabled={calcDisabled()} onClick={() => operate('批量删除')}>批量删除</Button> : null,
                        // hasAuthCode(-1) ? <Button key="btn6" disabled={calcDisabled()} onClick={() => operate('转回个人线索')}>转回个人线索</Button> : null,
                    //    hasAuthCode(-1) ? <Button key="btn6" disabled={calcDisabled()} onClick={() => operate('退回普通客户')}>退回普通客户</Button> : null,
                        hasAuthCode(2028) ? <BatchImport  key="bnt4" title="导入客户" params={{type: 2}} showFields={[]} trigger={<Button>导入客户</Button>} onSuccess={reFush} /> : null,
                        hasAuthCode(2026) ? <ExportButtonWithBatch key="btn5" disabled={calcDisabled()} param={getQueryParams} batchParams={batchParams} action={`${BASEURL}/exportCustomers`}></ExportButtonWithBatch> : null,
                       <Dropdown key="more" placement="bottomRight" arrow overlay={<Menu>
                            { hasAuthCode(2023) ? <Menu.Item key="btn1"><TransferCustomer key="bnt2" title="转移客户" selectedRowKeys={selectedRowKeys} trigger={<Button type="link" disabled={calcDisabled()}>转移客户</Button>} onSuccess={reFush} /></Menu.Item> : null }
                            { hasAuthCode(2112) ? <Menu.Item key="btn2"><CustomerToCluePoolButton key="btn3" type="link" disabled={calcDisabled()} params={{customerIds: selectedRowKeys, type: 1}} onSuccess={reFush}>移入线索池</CustomerToCluePoolButton></Menu.Item> : null }
                            { hasAuthCode(-1) ? <Menu.Item key="btn3"><Button type="link" key="btn6" disabled={calcDisabled()} onClick={() => operate('退回普通客户')}>退回普通客户</Button></Menu.Item> : null }
                            { hasAuthCode(2110) ? <Menu.Item key="btn4"><Button type="link" key="btn6" disabled={calcDisabled()} onClick={() => operate('批量删除')}>批量删除</Button></Menu.Item> : null }
                            { hasAuthCode(2530) ? <ExportButton key="btn5" type="link" param={{clueIds: selectedRowKeys, type: 0}} action={`${BASEURL}/customerAndAccountExprot`}>导出客户帐号归属</ExportButton> : null }
                            {/* { hasAuthCode(2110) ? <Menu.Item key="btn1"><Button type="link" size="small" loading={allLoding} onClick={() => operate('客户帐号归属导出')} {...rest} disabled={false}>客户帐号归属导出</Button></Menu.Item>:null} */}

                            </Menu>}>
                            <Button>更多操作</Button>
                        </Dropdown>,
                    ],
                }}
                rowKey="id"
                formRef={formRef}
                actionRef={ref as any}
                pagination={{defaultPageSize: 10}}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                        setSelectedRowKeys(selectedRowKeys)
                        setSelectedRows(selectedRows)
                    },
                    getCheckboxProps: (record: TableListItem) => ({
                        // disabled: record.isSyn === 1,
                        disabled: false
                    })
                }}
                onChange={(pageInfo,_, columnStatus,{action}) => {
                    if (action === 'sort') {
                        ref.current?.reload(1 as any)
                    }
                }}
                columnsState={{ persistenceKey, persistenceType: 'localStorage', defaultValue: columnStateDefaultValue}}
                searchState={{ persistenceKey: `${persistenceKey}:searchState`, persistenceType: 'localStorage' }}
                tableRender={(_: any, dom, ...rest) => {
                    return (<>
                        <CustomerBackflowAlert params={{systemScope: 52}} />
                        {dom}
                    </>)
                }}
            />
            <AddFollowRecord {...addFollowProps} modalProps={{onCancel: () => setAddFollowProps({...addFollowProps, visible:false}), maskClosable: false}} />
        </div>
    </div>
}


