import React, { Key, useRef, useState, useEffect } from "react"
import { columnsFn, TableListItem } from '../Search/data'
import ProTable from '@ant-design/pro-table';
import { Button, Tooltip } from "antd";
import { Icon } from '@components/index'
import { handleSorter, productApi } from "@utils/index";
import { lqGetRecommendData,} from '@api/index'
import { useHasAuthCode } from "@hooks/index";
import { AddPool, ClueToCustomerButton, GetCluesButton } from "@components/Customer/CluesPool";
import { lqCompanyToCustomerApi, lqGetClueToJnsApi, lqGetClueToPoolApi } from "../Search";
interface ActionType {
    reload: (resetPageIndex?: boolean) => void;
    reloadAndRest: () => void;
    reset: () => void;
    clearSelected?: () => void;
    startEditable: (rowKey: Key) => boolean;
    cancelEditable: (rowKey: Key) => boolean;
}

const getApi = productApi(lqGetRecommendData)

export default () => {
    const [, hasAuthCode] = useHasAuthCode()
    const ref = useRef<ActionType>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
    
    const getListApi = async (params: any, sorter: any, filter: any) => {
        const newSorter = handleSorter(sorter)
        const { data } = await getApi({...params, sorter: newSorter}, false)
        const { values, total } = data || {}
        return {
            data: values,
            success: true,
            total
        }
    }

    const operate = (type: any, record?: TableListItem | null) => {}
    const columns = columnsFn({type: 3, module: 3, fromPath: '/lq/recommend',}, operate)

    const addPoolOnFinish = async({cluePoolId}: any) => {
        const { success } = await lqGetClueToPoolApi({cluePoolId, ids: selectedRowKeys, module: 3}, true, reFresh) 
        return success
    }

    const reFresh = () => {
        setTimeout(() => {
            ref.current?.reload()
            // setSelectedRowKeys([])
            // @ts-ignore
            ref.current?.clearSelected()
        }, 1000)
    }

    const calcDisabled = (str: string) => {
        return !selectedRowKeys.length
    }

    return <div>
        <div style={{boxSizing: 'border-box'}}>
            <ProTable<TableListItem>
                scroll={{x: 1600}}
                columns={columns}
                request={getListApi}
                options={{reload: false, density: false, setting: false}}
                toolbar={{
                    actions: [
                        hasAuthCode(2227) ? <AddPool key="bnt3" title="转移线索" trigger={<Button type="primary" disabled={calcDisabled('转移线索')}>转移线索</Button>} onFinish={addPoolOnFinish}/> : null,
                        // hasAuthCode(2228) ? <GetCluesButton key="primary" disabled={calcDisabled('领取线索')} onOk={() => lqGetClueToJnsApi({ids: selectedRowKeys, module: 3}, true, reFresh)}>领取线索</GetCluesButton> : null,
                        hasAuthCode(2229) ? <ClueToCustomerButton key="btn1" disabled={calcDisabled('转为客户')} onOk={() => lqCompanyToCustomerApi({ids: selectedRowKeys, module: 3}, true, reFresh)}>转为客户</ClueToCustomerButton> : null,
                        // <ExportButton key="btn5" disabled={calcDisabled('批量导出')} param={{ids: selectedRowKeys}} action={`${LQBASEURL}/export`}></ExportButton>
                    ],
                }}
                rowKey="id"
                pagination={{defaultPageSize: 10}}
                actionRef={ref as any}
                headerTitle={<>优质企业<Tooltip title="系统根据大数据算法为您推荐优质企业，数量有限，先领先得。"><Icon type="icon-ziyuan" style={{marginLeft: 8, color: '#999'}}></Icon></Tooltip></>}
                search={false}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
                        setSelectedRowKeys(selectedRowKeys)
                    },
                    getCheckboxProps: (record: TableListItem) => ({
                        disabled: !record.isReceive,
                    })
                }}
            />
        </div>
    </div>
}
